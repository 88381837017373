import React, { useState, useRef } from 'react';
import '../css/reset.css';
import '../css/portfolio.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCoverflow, Scrollbar, FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar'; // 스크롤바 CSS
import { GrFormSchedule } from 'react-icons/gr';
// import { Autoplay, Navigation, EffectCoverflow, Scrollbar } from 'swiper/modules';

const Portfolio = () => {
    const [modalBg, setModalBg] = useState(false);
    const [modalContent, setModalContent] = useState(0);
    const swiperRef = useRef(null);

    function portfolioDetail(key) {
        setModalContent(key);
        setModalBg(true);
    }

    const portfolioDiv1 = e => {
        document.body.style.overflowY = 'hidden';
        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>허브블록 제어 모바일 앱</h4>
                    <button onClick={closeModal}></button>
                </div>
                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type1'>
                        <span className='portfolio_content_img1'></span>
                        <span className='portfolio_content_img2'></span>
                        <span className='portfolio_content_img3'></span>
                        <span className='portfolio_content_img4'></span>
                    </div>
                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>React Native 기반의 앱 개발</li>
                                <li>블루투스 4.0 loT 실시간통신</li>
                                <li>저전력 블루투스 BLE 호환</li>
                            </ul>
                        </div>
                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.07 ~ 2023.08</li>
                            </ul>
                        </div>
                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native</li>
                                <li>Bluetooth BLE</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv2 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>재고관리 솔루션 개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type2'>
                        <span className='portfolio_content_img1'></span>
                        <span className='portfolio_content_img2'></span>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>실시간 재고관리 솔루션 개발 (웹기반)</li>
                                <li>간편하고 쉬운 UI/UX</li>
                                <li>항목별 유통기한 관리</li>
                                <li>고객 맞춤형 솔루션</li>
                                <li>편리한 대시보드</li>
                                <li>RESTful API 개발</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2022.10 ~ 2023.01</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, RESTful API, Django, NodeJs, MySQL</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv3 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>경진대회 수상_라이프 로그 데이터 기반 치매 예측 Project</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type3'>
                        <span className='portfolio_content_img1'></span>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>라이프 로그 데이터 기반 치매 예측 Project</li>
                                <li>과기부에서 진행한 비공개 대회의 수치해석 분야 Task에서 2등 수상</li>
                                <li>웨어러블 디바이스를 통한 활동 및 수면 상태의 라이프 로그 데이터를 축적함</li>
                                <li>위의 데이터를 기반으로 치매 여부 예측 (정상, 경증 치매, 중증 치매)</li>
                                <li>Pytorch와 LighGBM 기반의 치매 예측 Model 생성</li>
                                <li>모델의 Ensemble을 통한 정확도 상승</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2021.07 ~ 2021.08</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Deep Learning, Machine Learning</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv4 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>최적발주량</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type4'>
                        <span className='portfolio_content_img1'></span>
                        <strong>일 평균 출고</strong>
                        <span className='portfolio_content_img2'></span>
                        <strong>최적발주량 시뮬레이션</strong>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>산업데이터를 사용하여 재고관리에 필요한 알고리즘 개발</li>
                                <li>Raw Data 가공 및 전처리</li>
                                <li>일평균출고, 출고빈도수 등 출고패턴 파악</li>
                                <li>
                                    리드타임, 출고대응일수 등 각종 재고관리 지식을 사용하여 출고에 따른 최적발주량 계산
                                </li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2021.03 ~ 2021.07</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, RESTful API, Django, NodeJs, MySQL</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv5 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>Datastudio 리포팅</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type5'>
                        <span className='portfolio_content_img1'></span>
                        <span className='portfolio_content_img2'></span>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>분석한 데이터를 토대로 시각적 데이터로 변환하여 대시보드로 표현하는 프로젝트</li>
                                <li>선, 막대그래프, 파이차트, 표로 변환하여 나타냄</li>
                                <li>사용자가 선택하는 기능에 따라 실시간 동적표현 가능하게 만듦</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.08 ~ 2023.10</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Python, SQL, Matplotlib, Seaborn, Datastudio</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv6 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>솔루션서버 성능테스트</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type6'>
                        <span className='portfolio_content_img1'></span>
                        <strong>테스트 결과</strong>
                        <span className='portfolio_content_img2'></span>
                        <strong>응답시간 그래프</strong>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>
                                    총 5개의 시나리오를 2개의 스레드로 30초간 로드밸런서에 지속적으로 요청(142시간
                                    테스트)
                                </li>
                                <li>총 요청당 32,148개의 요청을 처리, 평균 365ms의 반응속도</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.09 ~ 2023.09</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>AWS, Python, Jmeter</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv7 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>열처리 공정 불량 판별 AI모델 구축</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type7'>
                        <span className='portfolio_content_img1'></span>
                        <strong>열처리 생산공정 분류 모델</strong>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>열처리 생산공정의 불량단계를 분류하는 모델구축</li>
                                <li>데이터 품질지수확인, 종속변수 전처리, 데이터 라벨링</li>
                                <li>
                                    시계열 데이터 처리, 결측치/이상치 처리, 상관관계, 다중공산성 처리, 변수의 중요도
                                    분석, Feature selection
                                </li>
                                <li>XGBoost 성능 최적화</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.10 ~ 2023.11</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Python, SQL, ARIMA, SARIMA, LSTM, XGBoost, 시계열분석, 머신러닝, 딥러닝</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const portfolioDiv8 = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>쇼핑몰 제품에 대한 고객의 긍·부정 리뷰 분석</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type8'>
                        <span className='portfolio_content_img1'></span>
                        <strong>쇼핑몰 제품에 대한 고객의 리뷰 분석</strong>
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>셀레니움 자동화 프로그램을 사용한 크롤링</li>
                                <li>각 브랜드 별 상위 5개의 아우터 제품 사용</li>
                                <li>최신 리뷰글 크롤링</li>
                                <li>Konlpy, Ltokenizer, MaxScoreTokenizer, Soynl 적용</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.11 ~ 2023.12</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Python, 셀레니움, 크롤링, NLP, RNN, Transformer</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const leaspickWeb = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>자동차 매물 웹 사이트 개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap modal_type6'>
                        <img src='./img/portfolio/leasepickWeb/leasepick1.png' />
                        <img src='./img/portfolio/leasepickWeb/leasepick2.jpg' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>React 기반의 반응형웹사이트 개발</li>
                                <li>DB, 백앤드, 프론트앤드 개발</li>
                                <li>결제페이지 개발, 이메일서비스 기능</li>
                                <li>호스팅, 도메인 연결, 포털사이트 검색등록</li>
                                <li>PC웹, 모바일화면 호환성 제공</li>
                                <li>JavaScript, React, mysql, nodejs</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.12 ~ 2024.02</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, Node.js, MySQL, React, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const leaspickApp = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>자동차 매물 조회 앱개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox'>
                        <img src='./img/portfolio/leasepickApp/leasepickApp1.png' />
                        <img src='./img/portfolio/leasepickApp/leasepickApp2.png' />
                        <img src='./img/portfolio/leasepickApp/leasepickApp3.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>웹페이지도 구현완료</li>
                                <li>차량검색</li>
                                <li>전문가 상담 신청</li>
                                <li>고객데이터 기반 인기 차종 추천</li>
                                <li>특가 조회</li>
                                <li>카테고리별 조회(제조사 / 가격대)</li>
                                <li>기획전/이벤트</li>
                                <li>이용후기 게시판</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.12 ~ 2024.02</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, Node.js, MySQL, React, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const myPlace = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>위치기반 장소 저장 앱개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox'>
                        <img src='./img/portfolio/myPlace/myPlace1.png' />
                        <img src='./img/portfolio/myPlace/myPlace2.png' />
                        <img src='./img/portfolio/myPlace/myPlace3.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>원하는 조건의 장소 검색기능(필터검색)</li>
                                <li>찾은 장소를 리스트로 저장기능</li>
                                <li>예약기능 제공(캘린더)</li>
                                <li>방문예정 저장기능(캘린더)</li>
                                <li>경로 조회기능(찾아가기)</li>
                                <li>테마에 맞는 장소 추천기능</li>
                                <li>장소 정보 / 리뷰 조회 가능</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2024.03 ~ 2024.04</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, Node.js, MySQL, React, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const otter = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>GPS 기반 원격주문 앱개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox2'>
                        <img src='./img/portfolio/otter/otter1.png' />
                        <img src='./img/portfolio/otter/otter2.png' />
                        <img src='./img/portfolio/otter/otter3.png' />
                        <img src='./img/portfolio/otter/otter4.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>롤링이미지 : 신메뉴 출시 / 프로모션 진행 등</li>
                                <li>주문(사이렌 오더) : 메뉴 조회 / GPS기반 원격주문</li>
                                <li>스탬프 적립/쿠폰 : 사이렌 오더 시 자동적립</li>
                                <li>마이페이지 : 주문이력 / 쿠폰현황</li>
                                <li>관리자 페이지 : 메뉴 / 주문 / 회원 / 공지 / 프로모션 관리</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.07 ~ 2023.10</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, Node.js, MySQL, React, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const aiChat = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>⑤ AI 챗봇 서비스 개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox'>
                        <div className='aiChatImgBox'>
                            <img src='./img/portfolio/aiChat/aiChat1.png' />
                            <img src='./img/portfolio/aiChat/aiChat2.png' />
                        </div>
                        <img src='./img/portfolio/aiChat/aiChat3.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>전문블로그(URL) 크롤링</li>
                                <li>한글 자연어처리 Konlpy</li>
                                <li>Chat GPT, GPTs 사용</li>
                                <li>AWS lambda 사용</li>
                                <li>카카오빌더 연결</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2024.02 ~ 2024.03</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>AWS, chatgpt, GPTs, nlp, selenium, kakaobuilder, Python</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const flower = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>비대면 꽃배달 서비스 앱개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox'>
                        <img src='./img/portfolio/flower/flower1.png' />
                        <img src='./img/portfolio/flower/flower2.png' />
                        <img src='./img/portfolio/flower/flower3.png' />
                        <img src='./img/portfolio/flower/flower4.png' />
                        <img src='./img/portfolio/flower/flower5.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>롤링이미지 : 특가상품 / 프로모션 진행 등</li>
                                <li>상품 조회 : 카테고리 상품 / 실시간 베스트 / 검색</li>
                                <li>배달현황 : 배달 진행상황 / 주문내역 / 배달인증</li>
                                <li>템플릿 저장 : 자주 쓰는 꽃 종류 / 리본 배치 / 텍스트 템플릿 저장</li>
                                <li>예약발송 : 예정된 일정 등록 / 배달 예약 등록</li>
                                <li>관리자 페이지 : 상품 / 주문 / 회원 / 공지 / 프로모션 관리</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.11 ~ 2024.01</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, Node.js, MySQL, React, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const everything = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>실시간 물물교환 중개 앱개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox'>
                        <img src='./img/portfolio/everything/everything1.png' />
                        <img src='./img/portfolio/everything/everything2.png' />
                        <img src='./img/portfolio/everything/everything3.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>
                                    카테고리 별 물물교환 매물 확인 : 신발, 지갑, 의류, 전자기기 등 다양한 카테고리 지원
                                </li>
                                <li>내 물건 등록 : 직거래 / 택배 / 상세 / 원하는 물건 선택</li>
                                <li>선호도 등록 : 관심 카테고리 / 키워드 알림 / 거래 조건 알림</li>
                                <li>필터검색 : 카테고리 / 키워드 / 물건상태 / 거래방식 / 거래지역 / 가격</li>
                                <li>바자회 : GPS 기반으로 근처 매물 검색</li>
                                <li>찜한 물건 : 폴더별 관리 / 다른 사람이 찜한 물건 / 내 상품을 찜한 사람</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2023.10 ~ 2024.01</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, Node.js, MySQL, React, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const pickMe = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>쉽고 빠른 테이블 오더 서비스, 터치픽미(Touch Pick Me)</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox2'>
                        <img src='./img/portfolio/pickMe/pickMe1.png' />
                        <img src='./img/portfolio/pickMe/pickMe2.png' />
                        <img src='./img/portfolio/pickMe/pickMe3.png' />
                        <img src='./img/portfolio/pickMe/pickMe4.png' />
                        <img src='./img/portfolio/pickMe/pickMe5.png' />
                        <img src='./img/portfolio/pickMe/pickMe6.png' />
                        <img src='./img/portfolio/pickMe/pickMe7.png' />
                        <img src='./img/portfolio/pickMe/pickMe8.png' />
                        <img src='./img/portfolio/pickMe/pickMe9.png' />
                        <img src='./img/portfolio/pickMe/pickMe10.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>메인화면(메뉴 / 주문) : 카테고리별 메뉴 / 결제하기 / 직원호출</li>
                                <li>공지사항 / 이벤트 : 미리 설정한 공지사항 / 이벤트 노출</li>
                                <li>번역기능 : 설정한 언어의 메뉴판으로 쉽게 번역</li>
                                <li>결제하기 / 더치페이 : 간편결제 지원 / 더치페이를 통한 분할결제</li>
                                <li>
                                    관리자 페이지 (쿠폰 생성) : 메뉴 / 옵션 / 메뉴 카테고리 / 번역 메뉴 / 공지사항 /
                                    이벤트 / 쿠폰 등 생성
                                </li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2024.03 ~ 2024.06</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>React Native, Node.js, MySQL, React, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const linkfi = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>공유기앱 개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox2'>
                        <img src='./img/portfolio/linkfi/linkfi1.png' />
                        <img src='./img/portfolio/linkfi/linkfi2.png' />
                        <img src='./img/portfolio/linkfi/linkfi3.png' />
                        <img src='./img/portfolio/linkfi/linkfi4.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>직관적이고 심플한 UI/UX 제공</li>
                                <li>인터넷 접속시간 제어 및 통계 제공</li>
                                <li>한/영 버전 지원</li>
                                <li>PC와 모바일 화면 동시지원</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2024.06 ~ 2024.09</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Figma, React Native, html css javascript/Query, Android+iOS, Photoshop, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const clean = e => {
        document.body.style.overflowY = 'hidden';

        return (
            <div className='portfolio_modal_content'>
                <div className='portfolio_modal_header'>
                    <h4>수업 관리 앱 개발</h4>
                    <button onClick={closeModal}></button>
                </div>

                <div className='portfolio_modal_inner'>
                    <div className='portfolio_content_img_wrap appImgBox'>
                        <img src='./img/portfolio/clean/clean1.png' />
                        <img src='./img/portfolio/clean/clean2.png' />
                        <img src='./img/portfolio/clean/clean3.png' />
                        <img src='./img/portfolio/clean/clean3.png' />
                        <img src='./img/portfolio/clean/clean3.png' />
                    </div>

                    <div className='portfolio_content_text_wrap'>
                        <div>
                            <strong>[포트폴리오 설명]</strong>
                            <ul>
                                <li>강의 스케줄 등록</li>
                                <li>학생, 강사 등록</li>
                                <li>학생 결제 관리, 학원 매출 통계</li>
                                <li>강의신청, 출결 관리, 탑승 관리, 결석 신청, 탑승변경</li>
                                <li>학부모에게 결석/탑승 알림 메시지</li>
                                <li>결석 시 보강신청 및 보강수업 등록 기능</li>
                                <li>학생 별 수업 이력 확인</li>
                                <li>셔틀버스 코스 등록</li>
                                <li>홈 화면 대시보드 기능</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[참여기간]</strong>
                            <ul>
                                <li>2024.06 ~ 2024.12</li>
                            </ul>
                        </div>

                        <div>
                            <strong>[관련기술]</strong>
                            <ul>
                                <li>Figma, React Native, Android+iOS, Node.js, MySQL, Photoshop, React, RESTful</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const closeModal = () => {
        setModalBg(false);
        setModalContent(0);
        document.body.style.overflowY = 'auto';
    };

    const renderModalContent = () => {
        switch (modalContent) {
            case 'HUBBLOCK':
                return portfolioDiv1();
            case 'STOCKMANAGER':
                return portfolioDiv2();
            case 'CHIME_PREDICT':
                return portfolioDiv3();
            case 'EOQ':
                return portfolioDiv4();
            case 'DATASTUDIO':
                return portfolioDiv5();
            case 'SOLUTION_TEST':
                return portfolioDiv6();
            case 'HEATTREATMENT':
                return portfolioDiv7();
            case 'SHOPPING_REVIEW':
                return portfolioDiv8();
            case 'LEASE_WEB':
                return leaspickWeb();
            case 'LEASE_APP':
                return leaspickApp();
            case 'MY_PLACE':
                return myPlace();
            case 'OTTER':
                return otter();
            case 'AI_CHAT':
                return aiChat();
            case 'FLOWER':
                return flower();
            case 'EVERY':
                return everything();
            case 'PICK_ME':
                return pickMe();
            case 'LINCKFI':
                return linkfi();
            case 'CLEAN':
                return clean();
            default:
                return <></>;
        }
    };

    return (
        <>
            <section className='portfolio_wrap' id='portfolioSection' name='portfolioSection'>
                <div className='portfolio_inner'>
                    <div className='portfolio_title_wrap'>
                        <h3>PORTFOLIO</h3>
                        <strong>데이터파이브 포트폴리오</strong>
                    </div>
                    <div className='portfolio_box_wrap'>
                        <div className='portfolio_scroll_wrap scroll-container'>
                            <Swiper
                                ref={swiperRef}
                                onSwiper={swiper => {
                                    swiperRef.current = swiper;
                                }}
                                className='portfolio_box_slide'
                                spaceBetween={30}
                                slidesPerView={5}
                                modules={[Navigation, EffectCoverflow, Scrollbar, FreeMode]} // 여기서 Scrollbar 모듈 추가
                                loop={false}
                                direction='horizontal'
                                allowTouchMove={true}
                                freeMode={true}
                                scrollbar={{
                                    hide: false,
                                    draggable: true,
                                    dragSize: 100,
                                }}
                                breakpoints={{
                                    // (1) 0 ~ 767px 구간
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 10,
                                    },
                                    // (2) 768px 이상
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                    1000: {
                                        slidesPerView: 2.7,
                                        spaceBetween: 30,
                                    },
                                    1440: {
                                        slidesPerView: 2.5,
                                        spaceBetween: 40,
                                    },
                                }}
                            >
                                {/* 공유기 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img className='linkfiLogo' src='./img/portfolio/linkfi/logo.png' />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>공유기앱 개발</strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>인터넷 연결 설정 무선 네트워크 설정 앱</span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('LINCKFI')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 수업관리 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/portfolio_5.svg' />
                                                <p>수업 관리</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>수업 관리 앱 개발</strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>원스톱 수업 관리 앱</span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('CLEAN')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 리스픽 웹 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img
                                                    className='leasepickLogo'
                                                    src='./img/portfolio/leasepickApp/logo.png'
                                                />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        자동차 매물
                                                        <br />웹 사이트 개발
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>React 기반의 반응형 웹사이트 개발</span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('LEASE_WEB')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 리스픽 앱   */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img
                                                    className='leasepickLogo'
                                                    src='./img/portfolio/leasepickApp/logo.png'
                                                />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        자동차 매물
                                                        <br />
                                                        조회 앱개발
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    내가 원하는 매물을 쉽고 빠르게
                                                    <br />
                                                    찾을 수 있는 자동차 매물 조회 앱
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('LEASE_APP')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 허브블록 앱 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/portfolio_1.png' />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        허브블록 제어
                                                        <br />
                                                        모바일앱
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    모바일 앱 구축
                                                    <br />
                                                    환경 교육용 개발 용역 성공적 수행
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('HUBBLOCK')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 다닷 */}
                                <SwiperSlide>
                                    <div className='portfolio_box '>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/portfolio_4.png' />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <strong>
                                                    재고관리
                                                    <br />
                                                    솔루션 개발
                                                </strong>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    재고관리 솔루션 앱, 웹 구축
                                                    <br />
                                                    물류 현장 비즈니스 환경 개선 솔루션 개발
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('STOCKMANAGER')}
                                                    className='portfolio_btn2'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 챗봇 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/portfolio_5.svg' />
                                                <p>AI 챗봇</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        AI 챗봇
                                                        <br />
                                                        서비스 개발
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    물류박사 다닷봇(GPTs), <br />
                                                    다챗봇(카카오채널) 개발
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('AI_CHAT')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 장소저장 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/myPlace/logo.png' />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        위치기반 장소
                                                        <br />
                                                        저장 앱개발
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    나만의 장소 다이어리
                                                    <br />
                                                    위치기반 장소 저장 앱
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('MY_PLACE')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 원격주문 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/otter/logo.png' />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        GPS 기반
                                                        <br />
                                                        원격주문 앱개발
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>GPS 기반 원격주문 앱</span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('OTTER')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 꽃배달 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/flower/logo.png' />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        비대면 꽃배달
                                                        <br />
                                                        서비스 앱개발
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    배달현황 확인이 가능한
                                                    <br />
                                                    비대면 꽃배달 서비스
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('FLOWER')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 물물교환 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/everything/logo.png' />
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        실시간 물물교환
                                                        <br />
                                                        중개 앱개발
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>실시간 물물교환 중개 플랫폼</span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('EVERY')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                {/* 테이블 오더 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/pickMe/logo.png' />
                                            </div>
                                            <div className='portfolio_box_title_wrap pickme'>
                                                <>
                                                    <strong>
                                                        태블릿 원격주문 서비스
                                                        <br />
                                                        (테이블 오더) 개발
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>실시간 물물교환 중개 플랫폼</span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('PICK_ME')}
                                                    className='portfolio_btn1'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                {/* 셀레니움 데이터 분석*/}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/portfolio_3.svg' />
                                                <p>데이터 분석</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <strong>데이터분석</strong>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    셀레니움 자동화 프로그램을
                                                    <br />
                                                    사용한 크롤링
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('SHOPPING_REVIEW')}
                                                    className='portfolio_btn8'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* Datastudio 데이터 분석 */}
                                <SwiperSlide>
                                    <div className='portfolio_box '>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/portfolio_5.svg' />

                                                <p>데이터 분석</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <>
                                                    <strong>
                                                        Datastudio
                                                        <br />
                                                        리포팅
                                                    </strong>
                                                </>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    분석한 데이터를 토대로 시각적 데이터로
                                                    <br />
                                                    변환하여 대시보드로 표현하는 프로젝트 수행
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('DATASTUDIO')}
                                                    className='portfolio_btn5'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                {/* 솔루션서버 성능테스트 */}
                                <SwiperSlide>
                                    <div className='portfolio_box'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <img src='./img/portfolio/portfolio_5.svg' />
                                                <p>솔루션개발</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <strong>솔루션서버 성능테스트</strong>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    A 시나리오를 300개의 스레드로 30초간 로드
                                                    <br />
                                                    밸런서에 지속적으로 요청, 양호한 응답속도 구현
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('SOLUTION_TEST')}
                                                    className='portfolio_btn6'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                {/* <SwiperSlide>
                                    <div className='portfolio_box portfolio_2'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>대회 수상</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <strong>
                                                    경진대회
                                                    <br />
                                                    수상
                                                </strong>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    라이프 로그 데이터 기반 Project 은상
                                                    <br />
                                                    수치해석 분야 Task에서 2등 수상
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('CHIME_PREDICT')}
                                                    className='portfolio_btn3'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide> */}
                                {/* <SwiperSlide>
                                    <div className='portfolio_box portfolio_3'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>데이터분석</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <strong>데이터분석</strong>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    산업데이터를 사용하여 재고관리에 필요한
                                                    <br />
                                                    알고리즘 개발
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('EOQ')}
                                                    className='portfolio_btn4'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide> */}

                                {/* <SwiperSlide>
                                    <div className='portfolio_box portfolio_7'>
                                        <div className='portfolio_box_content_wrap'>
                                            <div className='portfolio_logo_wrap'>
                                                <span></span>
                                                <p>데이터 분석</p>
                                            </div>
                                            <div className='portfolio_box_title_wrap'>
                                                <strong>데이터분석</strong>
                                            </div>
                                            <div className='portfolio_produce_wrap'>
                                                <span>
                                                    열처리 생산공정의
                                                    <br />
                                                    불량단계를 분류하는 모델구축
                                                </span>
                                            </div>
                                            <div className='portfolio_detail_wrap'>
                                                <button
                                                    onClick={() => portfolioDetail('HEATTREATMENT')}
                                                    className='portfolio_btn7'
                                                >
                                                    자세히보기<span className='detail_arrow'></span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide> */}
                            </Swiper>
                            {/* <div className='swiper-nav'>
                                <button className='custom-prev-btn2 swiper-button-prev'></button>
                                <button className='custom-next-btn2 swiper-button-next'></button>
                            </div> */}
                        </div>
                    </div>

                    <div className={modalBg ? 'portfolio_modal_wrap' : 'blind'}>{renderModalContent()}</div>
                </div>
            </section>
        </>
    );
};

export default Portfolio;
